import React, { useEffect, useState } from "react";
import Map from "@arcgis/core/Map";
import SceneView from "@arcgis/core/views/SceneView";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import Graphic from "@arcgis/core/Graphic";
import Point from "@arcgis/core/geometry/Point";
import Polygon from "@arcgis/core/geometry/Polygon";
import PolygonSymbol3D from "@arcgis/core/symbols/PolygonSymbol3D";
import ExtrudeSymbol3DLayer from "@arcgis/core/symbols/ExtrudeSymbol3DLayer";
import SimpleFillSymbol from "@arcgis/core/symbols/SimpleFillSymbol";
import SimpleLineSymbol from "@arcgis/core/symbols/SimpleLineSymbol";
import ObjectSymbol3DLayer from "@arcgis/core/symbols/ObjectSymbol3DLayer";
import "@arcgis/core/assets/esri/themes/light/main.css";
import axios from "./axios";
import { Modal } from 'bootstrap'; 

const EsriMap = () => {
  const [buildings, setBuildings] = useState([]);
  const [accessData, setAccessData] = useState(null);
  const [mapObjects, setMapObjects] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [dataCoordinates, setDataCoordinates] = useState();
  const [formValues, setFormValues] = useState();
  const [threeDObjectData, set3dObjectData] = useState([]);
  const [objectData, setObjectData] = useState({});
  const [threeDObjectCategories, set3dObjectCategories] = useState([]);
  const [objectCategories, setObjectCategories] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]); // Track selected categories
  const [filteredBuildings, setFilteredBuildings] = useState([]);
  const [polygons, setPolygons] = useState([]);
  const [elevationData, setElevationData] = useState(null);
  const [polygonsVisibility, setPolygonsVisibility] = useState(true);
  const [objectVisibility, setObjectsVisibility] = useState(true);

  const getData = async () => {
    try {
      const res = await axios.get("/eharta-access/all");
      const responseData = res?.data?.data || res;
      setAccessData(responseData?.access);
    } catch (err) {
      console.log(err);
    }
  };

  const getElevationData = async () => {
    try {
      const res = await axios.get("/eharta-elevation/all");
      const responseData = res?.data?.data || res;
      setElevationData(responseData?.access);
    } catch (err) {
      console.log(err);
    }
  };

  const get3dObjectCategories = async () => {
    try {
      const res = await axios.get("/threeD-categories/all");
      const responseData = res?.data?.data || res;
      set3dObjectCategories(responseData);
    } catch (err) {
      console.log(err);
    }
  };
  const getObjectCategories = async () => {
    try {
      const res = await axios.get("/building-category/all");
      const responseData = res?.data?.data || res;
      setObjectCategories(responseData);
    } catch (err) {
      console.log(err);
    }
  };

  // const get3DObjectData = async () => {
  //   try {
  //     const res = await axios.get("/threeD-objects/all");
  //     const responseData = res?.data?.data || res;
  //     set3dObjectData(responseData);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  useEffect(() => {
    getData();
    getElevationData();
    // get3DObjectData();
    get3dObjectCategories();
    getObjectCategories();
  }, []);

  useEffect(() => {
    if (accessData) {
      axios
        .get("/building/all")
        .then((res) => {
          const responseData = res?.data?.data || res;
          setBuildings(responseData);
          setFilteredBuildings(responseData);
        })
        .catch((err) => {
          console.log(err);
        });

        axios.get("/polygons",'get')
        .then((res) => {
          const responseData = res?.data?.data || res;
          setPolygons(responseData);
        })
        .catch((err) => {
          console.log(err);
        });

      axios
        .get("/map-objects/all")
        .then((res) => {
          const responseData = res?.data?.data || res;
          setMapObjects(responseData);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [accessData]);

  const add3DObjectsFromArray = (objects) => {
    const graphicsLayer = new GraphicsLayer();
    if(objectVisibility){

    objects.forEach((obj) => {
      const objectPoint = new Point({
        longitude: obj.longitude,
        latitude: obj.latitude,
        z: elevationData ? obj.altitude: 0, // Use the altitude from the object
        spatialReference: { wkid: 4326 },
      });

      const objectSymbol = {
        type: "point-3d", // Autocasts as new PointSymbol3D()
        symbolLayers: [
          new ObjectSymbol3DLayer({
            resource: {
              href: process.env.REACT_APP_UPLOADS + "/" + obj.object_href, // Use the object_href from API response
            },
            height: obj.height, // Use the object's height
            tilt: obj.tilt,
            heading: obj.heading, // Use the object's heading
          }),
        ],
      };

      const objectGraphic = new Graphic({
        geometry: objectPoint,
        symbol: objectSymbol,
        attributes: {
          id: obj.id,
          name: obj.name,
          latitude: obj.latitude,
          longitude: obj.longitude,
          altitude: obj.altitude,
          height: obj.height,
          tilt: obj.tilt,
          heading: obj.heading,
          object_href: obj.object_href,
          objectDetail: "Object3D",
          createdAt: obj.createdAt,
          updatedAt: obj.updatedAt,
      },
      });

      graphicsLayer.add(objectGraphic);
    });
    } 
    else {
      graphicsLayer.removeAll(); // Hide polygons by removing them from the layer
    }

    return graphicsLayer;
  };

  useEffect(() => {
    if (accessData) {
      const map = new Map({
        basemap: "topo-vector",
        ground: elevationData ? "world-elevation" : null ,
      });

      const view = new SceneView({
        container: "viewDiv",
        qualityProfile: "high",
        map: map,
        camera: {
          position: [21.146644, 42.644623, 1590],
          tilt: 60,
        },
        environment: {
          lighting: {
            directShadowsEnabled: true,
            ambientOcclusionEnabled: true,
            date: new Date("2024-07-20T16:00:00"),
          },
        },
      });
      // const updateLighting = () => {
      //   const currentTime = new Date();
      //   let hours = currentTime.getHours();
  
      //   // If it's before 6 AM or after 6 PM, clamp to noon (12 PM)
      //   if (hours < 6 || hours > 13) {
      //     currentTime.setHours(12, 0, 0); // Set time to 12:00 PM
      //   }
  
      //   // Update lighting date to the modified time
      //   view.environment.lighting.date = currentTime;
      // };
      // Set interval to update lighting every 10 seconds
      // const intervalId = setInterval(updateLighting, 60000);
  
      // updateLighting();

      const graphicsLayer = new GraphicsLayer();
      map.add(graphicsLayer);

      if (polygonsVisibility) {
      let allRings = [];
      polygons.forEach(polygonData => {
        const rings = polygonData.polygon_points.map(point => [
          point.longitude, point.latitude, elevationData ? point.altitude : 0
        ]);
        allRings.push({ rings, color: polygonData.color, name: polygonData.name }); // Push the rings of the current polygon into the allRings array
    });
    allRings?.forEach(polygonData => {
      const { rings, color, name } = polygonData;
        let polygon = new Polygon({
          rings: [rings], // Use the rings for the current polygon
          spatialReference: { wkid: 4326 }, // WGS84 spatial reference
        });
      
        // Create a 3D symbol for the polygon
        const polygonSymbol = new PolygonSymbol3D({
          symbolLayers: [
            new ExtrudeSymbol3DLayer({
              material: { color: color || "green" }, // Set the color
              size: 0.1, // Height of the extrusion (e.g., building height)
            }),
          ],
        });
      
        // Create a graphic for the polygon and add it to the graphics layer
        const polygonGraphic = new Graphic({
          geometry: polygon, // Use the polygon geometry
          symbol: polygonSymbol, 
          attributes: {
            name: name, 
            objectDetail: "Polygon"
            // Include the name attribute
        },
          // Use the 3D symbol
        });
      
        // Assuming graphicsLayer is defined and added to the map
        graphicsLayer.add(polygonGraphic); // Add the polygon graphic to the layer
      });
    }else {
      graphicsLayer.removeAll(); // Hide polygons by removing them from the layer
    }


      const layer = add3DObjectsFromArray(mapObjects);
      map.add(layer);


      

      filteredBuildings?.forEach((building) => {
        addBuildingWithSpaces(building);
      });

      function addBuildingWithSpaces(building) {
        const {
          buildingName,
          convertedObjects,
          convertedParcels,
          floorsHeight,
          absoluteQuotas,
          heightFloors,
          colors,
        } = building;

        const sliceHeight = 0.2; // Total slice height

        // Add 2D outline with dynamic width
        const outlinePolygon = new Polygon({
          rings: convertedParcels,
          spatialReference: { wkid: 4326 },
        });

        const outlineSymbol = new SimpleFillSymbol({
          color: [0, 0, 0, 0],
          outline: new SimpleLineSymbol({
            color: [255, 0, 0],
            width: 2,
          }),
        });

        const outlineGraphic = new Graphic({
          geometry: outlinePolygon,
          symbol: outlineSymbol,
        });

        graphicsLayer.add(outlineGraphic);

        let currentAltitude = elevationData ? absoluteQuotas[0] : 0;

        let floorTest;
        if (floorsHeight === 1) {
          floorTest = new Polygon({
            rings: convertedObjects.map((ring) => [ring[0], ring[1], elevationData ? ring[2] : 0]),
            spatialReference: { wkid: 4326 },
          });
        }
        const color = colors[0 % colors.length];
        const floorSymbol = new PolygonSymbol3D({
          symbolLayers: [
            new ExtrudeSymbol3DLayer({
              material: { color: color },
              size:
                heightFloors[0] - (0 < floorsHeight - 1 ? sliceHeight / 2 : 0),
            }),
          ],
        });
        const floorGraphic2 = new Graphic({
          geometry: floorTest,
          symbol: floorSymbol,
          attributes: {
            buildingName: buildingName,
            objectDetail: "Perdhese",
            // floor: i + 1,
          },
        });

        graphicsLayer.add(floorGraphic2);

        for (let i = 0; i < floorsHeight; i++) {
          if (floorsHeight === 1) {
            break;
          }
          const altitude = elevationData ? absoluteQuotas[i] : currentAltitude ;
          const floorHeight =
            heightFloors[i] - (i < floorsHeight - 1 ? sliceHeight / 2 : 0);
          const color = colors[i % colors.length];

          const floorPolygon = new Polygon({
            rings: convertedObjects.map((ring) => [ring[0], ring[1], altitude]),
            spatialReference: { wkid: 4326 },
          });

          const floorSymbol = new PolygonSymbol3D({
            symbolLayers: [
              new ExtrudeSymbol3DLayer({
                material: { color: color },
                size: floorHeight,
              }),
            ],
          });

          const floorGraphic = new Graphic({
            geometry: floorPolygon,
            symbol: floorSymbol,
            attributes: {
              buildingName: buildingName,
              objectDetail: "BuildingDetail",
              floor: i + 1,
              totalFloors: floorsHeight,
            },
          });

          graphicsLayer.add(floorGraphic);

          if (!elevationData) {
            currentAltitude += floorHeight;
          }


          // Add slice (gap) between floors if it's not the last floor
          if (i < floorsHeight - 1) {
            const sliceAltitude = altitude + floorHeight;

            const slicePolygon = new Polygon({
              rings: convertedObjects.map((ring) => [
                ring[0],
                ring[1],
                elevationData ? sliceAltitude : currentAltitude,
              ]),
              spatialReference: { wkid: 4326 },
            });

            const sliceSymbol = new PolygonSymbol3D({
              symbolLayers: [
                new ExtrudeSymbol3DLayer({
                  material: { color: "#FFFFFF" },
                  size: sliceHeight,
                }),
              ],
            });

            const sliceGraphic = new Graphic({
              geometry: slicePolygon,
              symbol: sliceSymbol,
              attributes: {
                buildingName: buildingName,
                slice: i + 1,
              },
            });

            graphicsLayer.add(sliceGraphic);
          }
        }
      }

      view.on("click", (event) => {
        view.hitTest(event).then((response) => {
            // console.log("Response:", response);
            if (response.results.length) {
                const graphic = response.results.find((result) => result.graphic)?.graphic;
    
                if (graphic && graphic.attributes) {
                    const { objectDetail, id, name, height, latitude, longitude, buildingName, floor, totalFloors } = graphic.attributes;
    
                    let modalContent = "";
    
                    // Check the objectDetail attribute to determine the type of graphic
                    switch (objectDetail) {
                        case "BuildingDetail":
                            modalContent = `
                                <b>Building Name:</b> ${buildingName || "N/A"}<br>
                                <b>Total Floors:</b> ${totalFloors || 1}<br>
                            `;
                            break;
                        case "Polygon":
                            modalContent = `
                                <b>Polygon Name:</b> ${name || "N/A"}<br>
                                <b>Type:</b> Polygon<br>
                            `;
                            break;
                        case "Perdhese":
                            modalContent = `
                                 <b>Building Name:</b> ${buildingName || "N/A"}<br>
                                <b>Total Floors:</b> ${floor || 1}<br>
                            `;
                            break;
                        case "Object3D":
                            modalContent = `
                                <b>Name:</b> ${name || "N/A"}<br>
                                <b>Height:</b> ${height || "N/A"} meters<br>
                                <b>Latitude:</b> ${latitude || "N/A"}<br>
                                <b>Longitude:</b> ${longitude || "N/A"}<br>
                            `;
                            break;
                        default:
                            modalContent = "Unknown object type.";
                            break;
                    }
    
                    document.getElementById("modalBodyContent").innerHTML = modalContent;
    
                    const modalElement = document.getElementById('objectModal');
                    const objectModal = new Modal(modalElement);
                    objectModal.show();
                    // const objectModal = new bootstrap.Modal(document.getElementById("objectModal"));
                    // objectModal.show();
                } else {
                    console.log("No attributes available for this graphic.");
                }
            }
        }).catch((error) => {
            console.error("Error during hitTest:", error);
        });
    });
      // return () => clearInterval(intervalId);
    }
    // console.log('Current selectedCategories:', selectedCategories);
  }, [buildings, accessData, filteredBuildings, polygonsVisibility, objectVisibility]);

  if (accessData === null) {
    return <div>Loading...</div>;
  }

  if (!accessData) {
    return (
      <div className="container d-flex justify-content-center align-items-center vh-100">
        <div className="card shadow-lg">
          <div className="card-body text-center">
            <h1 className="display-4 text-danger">
              <i className="bi bi-exclamation-triangle-fill"></i>
            </h1>
            <h2 className="card-title">Access Denied</h2>
            <p className="card-text">
              You don't have the necessary permissions to view this content.
            </p>
          </div>
        </div>
      </div>
    );
  }

  const handleCheckboxChange = (categoryId) => {
    setSelectedCategories((prevSelected) => {
      if (categoryId === "all") {
        // If "All Buildings" is selected, reset and only select "all"
        // return ["all"];
        return [...prevSelected.filter((id) => id === "allPolygons" || id === "allObjects"), "all"];
      } 
      else {
        // If a category is already selected and you uncheck it, remove it from the array
        if (prevSelected.includes(categoryId)) {
          return prevSelected.filter((id) => id !== categoryId); // Remove category when unchecked
        } else {
          // If a new category is selected, add it to the array, keeping all previously selected ones
          return [...prevSelected, categoryId];
        }
      }
    });
  };

  const filterBuildings = () => {
    // Handle buildings filtering based on selected categories
    if (selectedCategories.includes("all") || selectedCategories.length === 0) {
      setFilteredBuildings(buildings); // Show all buildings if "All" is selected
    } else {
      // Filter buildings based on selected categories
      const filtered = buildings.filter((building) =>
        selectedCategories.includes(building.buildingCategory)
      );
      setFilteredBuildings(filtered); // Show filtered buildings
    }
  
    // Toggle polygonsVisibility based on the checkbox
    if (selectedCategories.includes("allPolygons")) {
      setPolygonsVisibility(true); // If polygons checkbox is checked, show polygons
    } else {
      setPolygonsVisibility(false); // If not checked, hide polygons
    }

    if (selectedCategories.includes("allObjects")) {
      setObjectsVisibility(true); // If polygons checkbox is checked, show polygons
    } else {
      setObjectsVisibility(false); // If not checked, hide polygons
    }
  }

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let saveObject = {
      name: objectData.name || "Test", //value e object qe e marr kur e preku select
      latitude: dataCoordinates?.latitude,
      longitude: dataCoordinates?.longitude,
      altitude: dataCoordinates?.z,
      object_href: objectData?.threeD_object_url || "/uploads/objects/tree.glb", //value e object qe e marr kur e preku select
      height: objectData?.height || 4, //value e object qe e marr kur e preku select
      tilt: objectData?.tilt || Number(formValues?.tilt) || 0,
      heading: objectData?.heading || Number(formValues?.heading) || 0,
    };
    if (objectData && Object.keys(objectData).length > 0) {
      axios
        .post("/map-objects", saveObject)
        .then((res) => {
          setModalOpen(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      alert("Please Select an object");
    }

    // setMapObjects((prevObjects) => {
    //   const updatedObjects = [...prevObjects, saveObject];

    //   // Call the function to update the map with the new objects
    //   add3DObjectsFromArray([saveObject]); // Only add the newly created object to the map

    //   return updatedObjects;
    // });
  };

  // const filteredObjects = selectedCategoryId
  //   ? threeDObjectData.filter(
  //       (el) => el.threeD_categorieId === selectedCategoryId
  //     )
  //   : threeDObjectData;
  return (
    <>
      <div id="viewDiv" style={{ height: "100vh", width: "100%" }}></div>
      {/* <div
        style={{ position: "absolute", top: "40%", left: "0.5%", zIndex: 100 }}
      >
        <button
          class="btn btn-secondary"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasExample"
          aria-controls="offcanvasExample"
        >
          <i class="fa fa-object-group"></i>
        </button>

        <div
          class="offcanvas offcanvas-start"
          tabindex="-1"
          id="offcanvasExample"
          aria-labelledby="offcanvasExampleLabel"
        >
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasExampleLabel">
              3D Objects
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div class="offcanvas-body">
            <div>Choose the object u want to add to the map</div>
            <div className="mt-5">
              <div className="mb-3">
                <label htmlFor="categorySelect" className="form-label">
                  Select Category
                </label>
                <select
                  id="categorySelect"
                  className="form-select"
                  onChange={(e) => setSelectedCategoryId(e.target.value)}
                >
                  <option value="">All Categories</option>
                  {threeDObjectCategories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>

              {filteredObjects.length > 0 ? (
                filteredObjects.map((el, index) => (
                  <div
                    className="col-md-12 d-flex align-items-center justify-content-between mb-3"
                    key={index}
                  >
                    <div className="col-md-2">
                      <img
                        src={`${process.env.REACT_APP_UPLOADS}/${el.threeD_object_image}`}
                        className="img-fluid"
                        alt={el.name}
                      />
                    </div>
                    <div className="col-md-4">
                      <p>{el.name}</p>
                    </div>
                    <div className="col-md-3">
                      <button
                        className="btn btn-outline-success"
                        data-bs-dismiss="offcanvas"
                        onClick={() => setObjectData(el)}
                      >
                        Zgjedh
                      </button>
                    </div>
                    <div className="col-md-1">
                      {objectData?.id === el.id ? (
                        <i className="fa fa-check text-success"></i>
                      ) : (
                        ""
                      )}
                    </div>
                    <hr />
                  </div>
                ))
              ) : (
                <p>No objects found for the selected category.</p>
              )}
            </div>
          </div>
        </div>
      </div> */}
      <div
        style={{ position: "absolute", top: "30%", left: "0.5%", zIndex: 95 }}
      >
        <button
          class="btn btn-secondary"
          onClick={() => setSelectedCategories([])}
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight"
        >
          <i class="fa fa-filter"></i>
        </button>

        <div
          class="offcanvas offcanvas-end"
          tabindex="-1"
          id="offcanvasRight"
          aria-labelledby="offcanvasRightLabel"
        >
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasExampleLabel">
              3D Objects
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div class="offcanvas-body">
            <div>Choose the object u want to see on the map</div>
            <div className="mt-5">
              <div className="mb-3">
              <div className="row justify-content-between">
              <div className="col-md-8">
                <label>All Buildings</label>
              </div>
              <div className="col-md-4">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={selectedCategories.includes("all")}
                  onChange={() => handleCheckboxChange("all")}
                />
              </div>
            </div>
                {objectCategories.map((category) => (
                  <>
                    <div
                      key={category.id}
                      className="row justify-content-between"
                    >
                      <div className="col-md-8">
                        <option key={category.id} value={category.id}>
                          {category.name}
                        </option>
                      </div>
                      <div className="col-md-4">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={selectedCategories.includes(category.name)}
                          onClick={() => handleCheckboxChange(category.name)}
                        />
                      </div>
                    </div>
                  </>
                ))}
              <div className="mt-5">Choose Polygons</div>
              <div className="row justify-content-between">
              <div className="col-md-8 mt-4 ">
                <label>All Polygons</label>
              </div>
              <div className="col-md-4">
                <input
                  className="form-check-input mt-4"
                  type="checkbox"
                  // checked={polygonsVisibility}
                  checked={selectedCategories.includes("allPolygons")}
                  onChange={() => handleCheckboxChange("allPolygons")}
                />
              </div>
            </div>

            <div className="mt-5">Choose 3D Objects</div>
              <div className="row justify-content-between">
              <div className="col-md-8 mt-4 ">
                <label>All 3D Objects</label>
              </div>
              <div className="col-md-4">
                <input
                  className="form-check-input mt-4"
                  type="checkbox"
                  // checked={polygonsVisibility}
                  checked={selectedCategories.includes("allObjects")}
                  onChange={() => handleCheckboxChange("allObjects")}
                />
              </div>
            </div>

                <button
                  className="btn btn-success w-100 mt-5"
                  data-bs-dismiss="offcanvas"
                  onClick={filterBuildings}
                >
                  Filter
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modalOpen && (
        <div
          className="modal-test"
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
            zIndex: 1000,
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          }}
        >
          <h2>Coordinates</h2>
          <p>Latitude: {dataCoordinates?.latitude}</p>
          <p>Longitude: {dataCoordinates?.longitude}</p>
          <p>Altitude: {dataCoordinates?.z}</p>
          <form>
            <div>
              <label htmlFor="tilt">Tilt:</label>
              <input
                type="number"
                id="tilt"
                name="tilt"
                className="form-control"
                onChange={handleChange}
                required
              />
            </div>

            <div>
              <label htmlFor="heading">Heading:</label>
              <input
                type="number"
                id="heading"
                name="heading"
                className="form-control"
                onChange={handleChange}
                required
              />
            </div>
            <div className="d-flex">
              <button
                className="btn btn-danger mt-4 w-50"
                type="submit"
                onClick={() => {
                  setModalOpen(false);
                  setFormValues(null);
                }}
              >
                Close
              </button>
              <button
                className="btn btn-success mt-4 w-50 ms-2"
                type="submit"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      )}
      <div class="modal fade" id="objectModal" tabindex="-1" aria-labelledby="objectModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="objectModalLabel">Object Details</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-start" id="modalBodyContent">
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
      {modalOpen && (
        <div
          className="modal-overlay-test"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 999,
          }}
          onClick={() => {
            setModalOpen(false);
            setFormValues(null);
          }} // Close the modal when the overlay is clicked
        />
      )}
    </>
  );
};

export default EsriMap;
