import React from 'react';
import './App.css';
import Esri3DMap from './esri3Dmap';
import HomePage from './esri3Dmap';
import MapWithConversion from './esri3Dmap';

function App() {
  return (
    <div className="App">
      <MapWithConversion />
    </div>
  );
}

export default App;
